/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'hand': {
    width: 20,
    height: 17,
    viewBox: '0 0 20 17',
    data: '<g _stroke="#1D1D44" _fill="none" fill-rule="evenodd"><path pid="0" d="M8.423 10.334l-1.22-1.947 1.218-2.11a.834.834 0 10-1.443-.833l-1.69 2.928a4.153 4.153 0 007.191 4.152l2.927-5.07a.764.764 0 10-1.323-.763l-1.71 2.962h0l2.212-3.83a.893.893 0 00-1.546-.893l-2.213 3.832h0l2.537-4.394a.834.834 0 00-1.444-.834l-.322.557h0L9.382 7.927l2.245-3.888a.828.828 0 10-1.435-.828l-1.813 3.14h0M6.379 3.096c-.59.282-1.248 1.054-1.975 2.312-.727 1.26-1.079 2.239-1.057 2.94M16.658 8.891c.05.653-.288 1.609-1.015 2.867-.727 1.26-1.4 2.055-2.017 2.385M5.175 1.92c-.622.337-1.339 1.21-2.15 2.615-.813 1.408-1.226 2.491-1.238 3.252M18.397 9.415c.019.708-.379 1.764-1.19 3.17-.812 1.406-1.545 2.305-2.197 2.698"/></g>'
  }
})
