/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'checklist': {
    width: 13,
    height: 18,
    viewBox: '0 0 13 18',
    data: '<g _stroke="#1D1D44" _fill="none" fill-rule="evenodd"><path pid="0" d="M1 1.5h7.667L12 4.833v11.334H1z"/><path pid="1" d="M2.528 6.903l.738.728 1.095-1.214M2.528 9.805l.738.729 1.095-1.215m-1.833 3.389l.738.729 1.095-1.215"/><path pid="2" stroke-linecap="square" d="M5.889 7.181H9.25m-3.361 2.902H9.25m-3.361 2.903H9.25"/><path pid="3" d="M12 4.833H8.667V1.5"/></g>'
  }
})
