/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'gear': {
    width: 18,
    height: 18,
    viewBox: '0 0 18 18',
    data: '<g _stroke="#1D1D44" _fill="none" fill-rule="evenodd"><path pid="0" d="M7.737 8.883l1.102 1.088 1.635-1.813"/><g transform="translate(1 1)"><path pid="1" d="M9.224 0a.25.25 0 01.25.25v1.824a6.083 6.083 0 011.856.808l1.257-1.255a.249.249 0 01.353 0l1.731 1.73a.25.25 0 010 .354l-1.33 1.33c.257.462.455.96.585 1.485h1.824a.25.25 0 01.25.25v2.448a.25.25 0 01-.25.25h-1.824a6.067 6.067 0 01-.694 1.674l1.29 1.29a.25.25 0 010 .354l-1.73 1.73a.25.25 0 01-.354 0l-1.29-1.29a6.067 6.067 0 01-1.674.694v1.824a.25.25 0 01-.25.25H6.776a.25.25 0 01-.25-.25v-1.824a6.075 6.075 0 01-1.485-.584l-1.33 1.329a.25.25 0 01-.354 0l-1.73-1.73a.25.25 0 010-.354l1.255-1.257a6.083 6.083 0 01-.808-1.856H.25a.25.25 0 01-.25-.25V6.776a.25.25 0 01.25-.25h1.824c.148-.597.384-1.16.694-1.674l-1.29-1.29a.25.25 0 010-.354l1.73-1.73a.25.25 0 01.354 0l1.29 1.29a6.067 6.067 0 011.674-.694V.25a.25.25 0 01.25-.25h2.448z"/><circle pid="2" cx="8" cy="8" r="3.789"/></g></g>'
  }
})
