/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'quotes': {
    width: 18,
    height: 13,
    viewBox: '0 0 18 13',
    data: '<path pid="0" d="M14.462 13C16.908 13 18 11.008 18 9.613c0-.2-4.578-.05-4.578-3.686 0-2.142 1.457-4.184 4.474-5.728L17.688 0c-5.098 1.743-7.96 5.13-7.96 8.268 0 2.44 1.665 4.732 4.734 4.732zm-9.728 0c2.445 0 3.538-1.992 3.538-3.387 0-.2-4.578-.05-4.578-3.686 0-2.142 1.456-4.184 4.474-5.728L7.96 0C2.861 1.743 0 5.13 0 8.268 0 10.708 1.613 13 4.734 13z" _fill="#161637" fill-rule="nonzero"/>'
  }
})
