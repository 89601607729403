/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'admin': {
    width: 6,
    height: 6,
    viewBox: '0 0 6 6',
    data: '<path pid="0" _fill="#9DCBC2" fill-rule="evenodd" d="M3.022 4.746l-1.764.927.337-1.964-1.427-1.39 1.972-.287.882-1.786.881 1.786 1.972.287-1.427 1.39.337 1.964z"/>'
  }
})
