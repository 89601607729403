/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'search': {
    width: 16,
    height: 17,
    viewBox: '0 0 16 17',
    data: '<g _fill="none" fill-rule="evenodd" _stroke="#154f4A" stroke-width="1.5" transform="translate(1 1)"><circle pid="0" cx="6" cy="6" r="6"/><path pid="1" d="M11 12l3.094 3.341"/></g>'
  }
})
