/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'previous_arrow': {
    width: 28,
    height: 28,
    viewBox: '0 0 28 28',
    data: '<defs><filter x="-12.5%" y="-12.5%" width="133.3%" height="133.3%" filterUnits="objectBoundingBox" id="svgicon_previous_arrow_a"><feMorphology radius=".5" operator="dilate" in="SourceAlpha" result="shadowSpreadOuter1"/><feOffset dx="1" dy="1" in="shadowSpreadOuter1" result="shadowOffsetOuter1"/><feGaussianBlur stdDeviation="1" in="shadowOffsetOuter1" result="shadowBlurOuter1"/><feComposite in="shadowBlurOuter1" in2="SourceAlpha" operator="out" result="shadowBlurOuter1"/><feColorMatrix values="0 0 0 0 0.505882353 0 0 0 0 0.505882353 0 0 0 0 0.505882353 0 0 0 0.2 0" in="shadowBlurOuter1"/></filter><circle pid="0" id="svgicon_previous_arrow_b" cx="12" cy="12" r="12"/></defs><g _fill="none" fill-rule="evenodd"><g transform="translate(1 1)"><use _fill="#000" filter="url(#svgicon_previous_arrow_a)" xlink:href="#svgicon_previous_arrow_b"/><use _stroke="#E1E1E1" _fill="#F4F4F6" xlink:href="#svgicon_previous_arrow_b"/></g><path pid="1" d="M14.707 9.464a1 1 0 00-1.414-1.414l-4.066 4.066a1.25 1.25 0 000 1.768l4.066 4.066a1 1 0 101.414-1.414L11.172 13l3.535-3.536z" _fill="#818181" fill-rule="nonzero"/></g>'
  }
})
