/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'chevron': {
    width: 8,
    height: 14,
    viewBox: '0 0 8 14',
    data: '<path pid="0" d="M6.5 2.05L1.55 7l4.95 4.95" _stroke="#F9F4EC" stroke-width="2.1" _fill="none" fill-rule="evenodd" stroke-linecap="square"/>'
  }
})
