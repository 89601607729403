/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'pump_1': {
    width: 40,
    height: 40,
    viewBox: '0 0 40 40',
    data: '<g _fill="none" fill-rule="evenodd"><path pid="0" _fill="#9DCBC2" d="M0 0h40v40H0z"/><path pid="1" d="M20 5.5c7.081 0 12.385 5.882 11.978 12.954-.252 4.373-3.708 8.693-10.291 13.682l1.8 1.608a.44.44 0 01.042.608.414.414 0 01-.316.148h-6.387a.425.425 0 01-.419-.43c0-.136.063-.264.168-.345l3.345-2.58c.002-.008.006-.015.011-.019 6.613-4.916 9.987-8.957 10.207-12.781.346-6.012-4.13-10.976-10.138-10.976S9.516 12.333 9.862 18.345c.197 3.427 2.559 6.208 7.905 10.533l-1.604 1.09c-5.42-4.484-7.913-7.55-8.141-11.514C7.615 11.382 12.919 5.5 20 5.5z" _fill="#F9F4EC" fill-rule="nonzero"/></g>'
  }
})
