/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'make_guest': {
    width: 18,
    height: 16,
    viewBox: '0 0 18 16',
    data: '<g _fill="#154F4A" fill-rule="nonzero"><path pid="0" d="M2 2a1 1 0 00-1 1v10a1 1 0 001 1h14a1 1 0 001-1V3a1 1 0 00-1-1H2zm0-1h14a2 2 0 012 2v10a2 2 0 01-2 2H2a2 2 0 01-2-2V3a2 2 0 012-2z"/><path pid="1" d="M6 8.5a2.5 2.5 0 110-5 2.5 2.5 0 010 5zm0-1a1.5 1.5 0 100-3 1.5 1.5 0 000 3z"/><path pid="2" d="M7 11.5a.5.5 0 110 1H3.85c-.74 0-1.34-.6-1.34-1.34l.003-.083C2.66 8.737 4.03 7.5 6.456 7.5a.5.5 0 010 1c-1.907 0-2.832.835-2.945 2.639v.021c0 .188.152.34.34.34H7z"/><path pid="3" d="M5.01 11.5a.5.5 0 100 1h3.15c.74 0 1.34-.6 1.34-1.34 0-.042 0-.042-.003-.083C9.351 8.737 7.981 7.5 5.555 7.5a.5.5 0 000 1c1.907 0 2.832.835 2.944 2.639l.001.021a.34.34 0 01-.34.34H5.01zM11 5.5a.5.5 0 110-1h3.5a.5.5 0 110 1H11zm0 3a.5.5 0 110-1h3.5a.5.5 0 110 1H11zm0 3a.5.5 0 110-1h3.5a.5.5 0 110 1H11z"/></g>'
  }
})
