/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'download_more_actions_green': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M8 10.536l3.39-3.39a.5.5 0 01.706.708L8.03 11.919a.75.75 0 01-1.06 0L2.904 7.854a.5.5 0 01.707-.708L7 10.536V1.5a.5.5 0 011 0v9.036zM0 10.5a.5.5 0 111 0v4.285c0 .075.195.214.5.214h12c.305 0 .5-.139.5-.214V10.5a.5.5 0 111 0v4.285c0 .714-.7 1.214-1.5 1.214h-12C.7 16 0 15.5 0 14.785V10.5z" _fill="#154f4a" fill-rule="nonzero"/>'
  }
})
