/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'calendar': {
    width: 24,
    height: 26,
    viewBox: '0 0 24 26',
    data: '<path pid="0" _fill="#154F4A" d="M7.25-.25v2.5h9.5v-2.5h1.5v2.5H22c.966 0 1.75.784 1.75 1.75v20A1.75 1.75 0 0122 25.75H2A1.75 1.75 0 01.25 24V4c0-.966.784-1.75 1.75-1.75h3.75v-2.5h1.5zm15 8.5H1.75V24c0 .138.112.25.25.25h20a.25.25 0 00.25-.25V8.25zm-7.4 4.672l1.114 1.004-4.352 4.828-3.139-3.098 1.054-1.067 2.022 1.995 3.301-3.662zM22 3.75H2a.25.25 0 00-.25.25v2.75h20.5V4a.25.25 0 00-.25-.25z"/>'
  }
})
