/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'open-new-window': {
    width: 449,
    height: 448,
    viewBox: '0 0 449 448',
    data: '<path pid="0" d="M369.41 44.8L205.75 208.46c-9.606 9.605-9.621 25.223.004 34.844 9.602 9.602 25.223 9.625 34.844 0l163.59-163.59v54.711c0 12.336 10.03 22.383 22.402 22.383 12.344 0 22.398-10.023 22.398-22.383V22.385c0-6.164-2.504-11.758-6.555-15.812-4.058-4.059-9.648-6.57-15.828-6.57h-112.04c-12.336 0-22.383 10.027-22.383 22.398 0 12.348 10.023 22.402 22.383 22.402l54.845-.003zm79.586 224V162.32v229.85c0 30.836-22.215 55.832-49.656 55.832H50.65c-27.426 0-49.656-25.047-49.656-55.832V55.83C.994 24.994 23.209-.002 50.65-.002h239.12H180.2c12.371 0 22.402 10.027 22.402 22.398S192.571 44.798 180.2 44.798H58.56c-7.125 0-12.754 6.691-12.754 14.941v328.52c0 8.102 5.71 14.941 12.754 14.941h332.89c7.125 0 12.754-6.691 12.754-14.941v-119.46c0-12.371 10.031-22.402 22.402-22.402s22.398 10.031 22.398 22.402l-.008.001z" _fill="#000" fill-rule="nonzero"/>'
  }
})
