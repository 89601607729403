/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'open_gray': {
    width: 9,
    height: 6,
    viewBox: '0 0 9 6',
    data: '<path pid="0" d="M7.425.89a.5.5 0 11.707.706L4.773 4.955a.75.75 0 01-1.06 0L.352 1.596A.5.5 0 111.062.89l3.182 3.182L7.425.89z" _fill="#818181" fill-rule="nonzero"/>'
  }
})
