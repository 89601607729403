/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'help_center_hover': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<g _fill="#154f4a" fill-rule="nonzero"><path pid="0" d="M10 19.5a9.5 9.5 0 110-19 9.5 9.5 0 010 19zm0-1a8.5 8.5 0 100-17 8.5 8.5 0 000 17z"/><path pid="1" d="M10 15.75a5.75 5.75 0 110-11.5 5.75 5.75 0 010 11.5zm0-1a4.75 4.75 0 100-9.5 4.75 4.75 0 000 9.5z"/><path pid="2" d="M8.375.875h1v4.353h-1zM10.625.875h1v4.244h-1zM8.375 14.739h1v4.167h-1zM10.625 14.814h1v4.092h-1zM.875 9.375v-1h4.418v1zM.875 11.625v-1h4.418v1zM14.826 9.375v-1h3.967v1zM14.692 11.625v-1h4.1v1z"/></g>'
  }
})
