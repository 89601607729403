/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'eye': {
    width: 26,
    height: 17,
    viewBox: '0 0 26 17',
    data: '<g transform="translate(1)" stroke-width="1.5" _stroke="#154F4A" _fill="none" fill-rule="evenodd"><circle pid="0" _fill="#FFF" cx="12.3" cy="12" r="3.6"/><path pid="1" d="M0 11.1c3.55-3.307 7.55-4.96 12-4.96s8.45 1.653 12 4.96" stroke-linecap="square"/><path pid="2" d="M12.3 4.2V0M16.5 4.657L17.587.6M7.987 4.657L6.9.6"/></g>'
  }
})
