/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'add': {
    width: 9,
    height: 9,
    viewBox: '0 0 9 9',
    data: '<path pid="0" _fill="#9DCBC2" d="M5.25.25v3.5h3.5v1.5h-3.5v3.5h-1.5v-3.5H.25v-1.5h3.5V.25h1.5z"/>'
  }
})
