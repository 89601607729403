/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'check-active': {
    width: 15,
    height: 15,
    viewBox: '0 0 15 15',
    data: '<g _fill="none" fill-rule="evenodd"><rect pid="0" width="15" height="15" _fill="#DE532A" rx="1"/><path pid="1" _fill="#F9F4EC" fill-rule="nonzero" d="M5.057 6.569L4 7.633l2.976 2.956 4.111-4.588L9.97 5 6.913 8.412z"/></g>'
  }
})
