/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'view_balloons': {
    width: 28,
    height: 18,
    viewBox: '0 0 28 18',
    data: '<g transform="translate(1)" _stroke="#F9F9F9" stroke-width="2" _fill="none" fill-rule="evenodd"><path pid="0" d="M12.992 1C8.599.992 4.68 3.351 1.19 8.18a1 1 0 00-.009 1.16c3.366 4.801 7.284 7.152 11.812 7.152 4.527 0 8.446-2.35 11.812-7.15a1 1 0 00-.01-1.162c-3.49-4.812-7.41-7.172-11.803-7.18z"/><circle pid="1" cx="13.032" cy="8.639" r="5"/></g>'
  }
})
