/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'dropdown_open_light': {
    width: 10,
    height: 6,
    viewBox: '0 0 10 6',
    data: '<path pid="0" fill-rule="nonzero" d="M4.929 3.712L1.295 0 .234 1.06l4.773 4.774L9.78 1.06 8.72 0z" _fill="#f9f4ec"/>'
  }
})
