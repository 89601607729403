/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'more_actions_gray': {
    width: 20,
    height: 4,
    viewBox: '0 0 20 4',
    data: '<g _fill="#818181" fill-rule="evenodd"><circle pid="0" cx="2" cy="2" r="2"/><circle pid="1" cx="10" cy="2" r="2"/><circle pid="2" cx="18" cy="2" r="2"/></g>'
  }
})
