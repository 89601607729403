/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'remove': {
    width: 7,
    height: 7,
    viewBox: '0 0 7 7',
    data: '<path pid="0" _fill="#154F4A" d="M5.95 0L7 1.05 4.55 3.5 7 5.95 5.95 7 3.5 4.55 1.05 7 0 5.95 2.45 3.5 0 1.05 1.05 0 3.5 2.45 5.95 0z"/>'
  }
})
