/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'open_details': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: '<g _fill="none" fill-rule="evenodd"><path pid="0" _fill="#E3B8AE" d="M31 0H0v32h31a1 1 0 001-1V1a1 1 0 00-1-1z"/><path pid="1" _stroke="#F9F4EC" stroke-linecap="square" stroke-width="2.1" d="M16 11.05L20.95 16 16 20.95"/></g>'
  }
})
