/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'drawer_close': {
    width: 8,
    height: 14,
    viewBox: '0 0 8 14',
    data: '<path pid="0" d="M6.657 1L1.318 6.339a.45.45 0 000 .636l5.339 5.339" _stroke="#FFF" stroke-width="2" _fill="none" fill-rule="evenodd" stroke-linecap="round"/>'
  }
})
