/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'activate': {
    width: 17,
    height: 18,
    viewBox: '0 0 17 18',
    data: '<path pid="0" _fill="#154F4A" d="M10.629 13.665l.742.67-3.336 3.701-2.386-2.355.702-.711 1.641 1.62 2.637-2.925zM8.818.5a3.65 3.65 0 013.639 3.936l-.261 3.322a3.644 3.644 0 01-1.415 2.608c.623.47 1.41.774 2.37.913l.3.037c1.703.179 2.608 1.298 2.623 3.185l-1 .008c-.01-1.334-.506-2.01-1.564-2.177l-.163-.021c-1.444-.151-2.617-.624-3.504-1.422-.4.15-.834.233-1.286.233h-.224a3.638 3.638 0 01-1.505-.324c-.897.854-2.103 1.356-3.601 1.513l-.163.021c-1.058.167-1.553.843-1.564 2.177l-1-.008c.016-1.887.92-3.006 2.623-3.185l.301-.037c1.042-.15 1.883-.498 2.53-1.04a3.64 3.64 0 01-1.26-2.481l-.26-3.322A3.65 3.65 0 018.072.5h.746zm0 1h-.746A2.65 2.65 0 005.43 4.358l.261 3.321a2.65 2.65 0 002.642 2.443h.224a2.65 2.65 0 002.642-2.443l.26-3.321A2.65 2.65 0 008.819 1.5z"/>'
  }
})
