/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'checkbox_complete': {
    width: 16,
    height: 16,
    viewBox: '0 0 15 15',
    data: '<rect pid="0" x=".5" y=".5" width="14" height="14" rx="1" _fill="none" _stroke="#9dcbc2"/><path pid="1" _fill="#9dcbc2" d="M5 6.3l-1 1 3 3 4-4.6-1.1-1-3 3.4L5 6.3z"/>'
  }
})
